import './App.css';
import { useEffect, useState, useRef } from 'react';
import '@chatui/core/es/styles/index.less';
import Chat, { 
    Bubble, 
    useMessages,
    Card, 
    CardTitle, 
    List, 
    ListItem,
    Flex, 
    Icon,
    RichText,
} from '@chatui/core';
import '@chatui/core/dist/index.css';
import axios from 'axios';
// const rootAvatar = require('../public/logo192.png');
import rootAvatar from './assets/rootAvatar.png';

// 引入对话框组件
import ChatUiToZy from './components/chatui_zy';
const rootUser = { avatar: rootAvatar};

const initialMessage = [
    {
        type: 'text',
        content: { 
            text: '您好，我是您的AI智能客服小亿，很高兴为您服务！' 
        },
        user: rootUser,
    },
    // {
    //     "type": "card",
    //     "content": {
    //     "code": "list-v2",
    //     "data": {
    //         // image: 'https://img.alicdn.com/imgextra/i3/O1CN012orgji20hYGr8kAvd_!!6000000006881-0-tps-186-528.jpg',
    //         "autoSize": true,
    //         "type": "card",
    //         "title": "猜你想问：",
    //         "list": [
    //             {
    //                 "action": "sendText",
    //                 "title": "如何绑定设备头？",
    //                 hot: true,
    //             },
    //             {
    //                 "action": "sendText",
    //                 "title": "如何充值设备流量？"
    //             },
    //             {
    //                 "action": "sendText",
    //                 "title": "设备报警功能如何设置？"
    //             },
    //             {
    //                 "action": "sendText",
    //                 "title": "如何购买云存服务？"
    //             },
    //         ],
    //     }
    //     },
    //     user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
    // }

];

function getUid () {
    var uid = '';
    for (var i = 0; i < 10; i++) {
        uid += String(Math.floor(Math.random()*10));
    }
    return uid+''+Date.now();
}

const App = ()=> {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const [initMessage, setInitialMessage] = useState(initialMessage);
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);

    const searchData = {
        deviceId: searchParams.get('deviceId'),
        userId: searchParams.get('userId'),
        appVersion: searchParams.get('appVersion'),
        mobileMode: searchParams.get('mobileMode'),
    };
    console.log(searchData);
    
    const [sessionId, setSessionId] = useState(getUid());
    const [msgList, handleMsgList] = useState([]);

    const { messages, appendMsg } = useMessages(initialMessage);
    console.log('messages');
    console.log(messages);
    const params = {
        imDomain: "alimeim.aliyuncs.com",
        appKey: "qidRLr72",
        token: "112312"
    }

    const getChatToken = ()=> {
        return new Promise((resolve, reject) => {
            axios.post('/sys/zyCloudDeviceProblem/getToken',{}).then(res => {
                console.log(res);
                if (res?.data?.code == 0) {
                    Object.assign(params,res.data.data);
                    resolve(params.token);
                }
            });
            resolve(params.token);
        });
    }

    const getWelcomeMsg = () => {
        return new Promise((resolve, reject) => {
            axios.get(`/sys/zyCloudDeviceProblem/getWelcomeMessage`,{}).then(res => {
                console.log(res);
                if (res.data.code == 0) {
                    const firstMsg = {
                        type: 'text',
                        content: { 
                            text: res.data.data.body.welcomeMessage 
                        },
                        user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                    };
                    setInitialMessage([firstMsg]);
                }
              }).catch(err => {
                console.log(err);
              })
        });
    }

    // 发送消息
    const sendChatMsg = (msg) => {
        axios.post('/sys/zyCloudDeviceProblem/createChat',{
            "deviceId": searchData.deviceId,
            "userId": searchData.userId,
            "customerProblem": msg,
            "appVersion": searchData.appVersion,
            "mobileMode": searchData.mobileMode,
            "sessionId": sessionId,
        }).then(res => {
            console.log('sendChatMsg');
            console.log(res);
            if (res.data.code != 0) {
                return;
            }
            var callbackMessages = res.data.data.body.messages[0];
            // setMessageId(res.data.data.body.messageId);
            var chatMsgData = {};
            console.log(callbackMessages);
            if (callbackMessages.answerType == "Text") {
                chatMsgData = {
                    type: 'text',
                    content: {
                        text: callbackMessages.text.content, 
                    },
                    position: 'left',
                    // user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                    user: rootUser
                }
            }else if (callbackMessages.answerType == 'Recommend') {
                chatMsgData = {
                    type: 'card',
                    content: {
                        code: callbackMessages.answerType,
                        data: {
                            title: callbackMessages.title,
                            list: callbackMessages.recommends,
                        }
                    },
                    position: 'left',
                    // user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                    user: rootUser
                };
            }else if (callbackMessages.answerType == 'Knowledge') {
                chatMsgData = {
                    type: 'card',
                    content: {
                        code: 'knowledge',
                        data: {
                            text: callbackMessages.knowledge.content,
                            childKnowledges: callbackMessages.knowledge.relatedKnowledges,
                        },
                    },
                    position: 'left',
                    // user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                    user: rootUser
                };
            }
            console.log('chatMsgData');
            console.log(chatMsgData);
            chatMsgData = Object.assign(chatMsgData,{messageId: res.data.data.body.messageId})
            setTimeout(() => {
                appendMsg(chatMsgData);
            }, 500);
        }).catch(err => {

        })
    }
    
    // 点赞 点踩
    const chatLikeOrStomp = (feedback,messageId)=> {
        axios.post('/sys/zyCloudDeviceProblem/chatLikeOrStomp',{
            messageId: messageId,
            feedback: feedback,
            sessionId: sessionId
        }).then(res => {
            if (res.data.code === 0) {
                var feedMsg = '谢谢您给我的赞，我会继续努力！'
                if (feedback === 'bad') {
                    feedMsg = "抱歉，给您带来不好的体验，我会继续学习，下次为您提供更好的服务！";
                }
                msgList.push({
                    messageId,
                    feedback,
                });
                handleMsgList(msgList);
                appendMsg({
                    type: 'text',
                    content: {
                        text: feedMsg,
                    },
                    // user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                    user: rootUser,
                    position: 'left'
                });
                return;
            }
        }).catch(err => {
            console.log(err);
        })
    }


    var im; 
    const init = async () => {
        im = new window.IMSDK({
            config: {
                url: `wss://${params.imDomain}/alime/im`,
                token: params.token,
                appKey: params.appKey,
                getToken: () => {
                    return getChatToken();
                    // return 
                },
                loadMoreText: '点击加载更多',
                // （可选）进入页面时，是否自动触发加载历史消息操作
                autoLoadMore: true,
            },
            components: {
                'adaptable-action-card': 'https://g.alicdn.com/ume/chatbot/6.0.4/list-v2.js', 
                // 推荐主动指定 name 属性
                'adaptable-action-card': {
                    name: 'AlimeComponentAdaptableActionCard',
                    url: 'https://g.alicdn.com/ume/chatbot/6.0.4/list-v2.js'
                },
            },
            robot: {
                avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg'
            },
            handlers: {
                onOpen: () => {
                    console.log('open');
                },
                onClose: err => {
                    console.log('close', err);
                },
                onError: err => {
                    console.log('error', err);
                },
                onReconnect: ({ code, err }) => {
                    console.log('reconnect', code, err);
                },
                onMessage: data => {
                    const result = data.body;
                    // const callbackMsg = result.message[0];
                    if (result && result.message.length > 0) {
                        const callbackMsg = result.message[0];
                        if (callbackMsg.msgType == 'text') {
                            appendMsg({
                                type: callbackMsg.msgType,
                                content: callbackMsg.content,
                                // content: {
                                //     text: JSON.stringify(data.body.message[0].content.text),
                                // },
                                user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                            });
                        }else if (callbackMsg.msgType == 'card') {
                            if (callbackMsg.content.code != 'knowledge') {
                                appendMsg({
                                    type: callbackMsg.msgType,
                                    content: {
                                        code: callbackMsg.content.code,
                                        data: {
                                            "autoSize": true,
                                            "type": callbackMsg.content.code,
                                            title: callbackMsg.content.data.title,
                                            list: callbackMsg.content.data[callbackMsg.content.code],
                                        },
                                    },
                                    user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                                });
                            }else {
                                appendMsg({
                                    type: callbackMsg.msgType,
                                    content: {
                                        code: callbackMsg.content.code,
                                        data: callbackMsg.content.data,
                                    },
                                    user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
                                });
                            }
                        }
                    }
                    
                    console.log('message', data);
                }
            }
        });
        im.start();
        im.setCommonParams({
            senderId: 'senderId',
            senderNick: 'senderNick',
            senderRole: 1,
        });
    }

    const initConfig = async () => {
        await getChatToken();
    }

    useEffect(() => {
        // init();
    });

    // useEffect(() => {
    //     initConfig();
    // },[]);
    function handleSend(type, val) {
        if (type === 'text' && val.trim()) {
            // im.sendMessage({
            //     msgType: 'text',
            //     content: {
            //         text: val,
            //     },
            // });
            sendChatMsg(val);
            appendMsg({
                type: 'text',
                content: { text: val },
                position: 'right',
            });
        }
    }
    // 快捷短语回调，可根据 item 数据做出不同的操作，这里以发送文本消息为例
    function handleQuickReplyClick(item) {
        handleSend('text', item.name);
    }
    function renderMessageContent(msg) {
        var regex = /[？?]/; // 匹配中英文问号
        const { type, content, messageId, } = msg;
        console.log('renderMessageContent');
        console.log(msg);
        var list = [];
        if (type === 'card') {
            list = content.data.list;
        }
        console.log(list);
        // 根据消息类型来渲染
        switch (type) {
        case 'text':
            return <Bubble type='text' content={content.text} />;
        case 'image':
            return (
            <Bubble type="image">
                <img src={content.picUrl} alt="" />
            </Bubble>
            );
        case "card":
            var msgItem = msgList.find(v => v.messageId === messageId);
            console.log('msgItem');
            console.log(msgItem);
            return content.code !== 'knowledge' ? (
                <Card fluid={true} size="xl" style={{display: content.data.image ?'flex': 'black',}}>
                    {
                        content.data.image ? <Bubble type="image" style={{margin: '0px'}}>
                            <img src={content.data.image} alt="" />
                        </Bubble> : <CardTitle>{content.data.title}</CardTitle>
                    }
                    <div className='list-box'>
                        <List>
                            {
                                list.map((item,index) => {
                                    var showContent = regex.test(item.title) ? item.title : item.title+'？';
                                    return <div key={index} className='list-item'>
                                        <ListItem content={showContent} style={{color: '#39f'}} onClick={() =>{
                                            handleSend('text', item.title);
                                        }} ></ListItem>
                                        <Icon type="chevron-right" style={{color: 'rgba(0,0,0,0.26)'}} />
                                    </div>
                                })
                            }
                        </List>
                    </div>
                </Card>
            ): (
                <Flex alignItems='flex-end'>
                    {/* fluid={true}   style={{width: 'calc(100% - 60px)'}}*/}
                    <Card className='knowledge-box' style={{flex: '1'}}>
                        <div className='richText-box'>
                            <RichText content={content.data.text} />
                            <div>
                                <div className='gray-text'>猜你还想了解</div>
                                {
                                    content.data.childKnowledges.length > 0 ? <div>
                                        {
                                            content.data.childKnowledges.map((item,index) => {
                                                return <div className='childKnowledges-title' key={index} onClick={()=> {
                                                    handleSend('text', item.title);
                                                }}>{item.title}</div>
                                            })
                                        }
                                    </div> : null
                                }
                            </div>
                        </div>
                    </Card>
                    <div className='margin-10 flex-center'>
                        {
                            (!msgItem || (msgItem && msgItem.feedback === 'good')) ? <div className={`like-box ${msgItem && msgItem.feedback === 'good'?'like-act-good':''}`} 
                            onClick={() => {
                                chatLikeOrStomp('good',messageId)
                            }}>
                                <Icon type="thumbs-up" />
                            </div>
                            : null
                        }
                        {
                            (!msgItem || (msgItem && msgItem.feedback === 'bad')) ? <div className={`like-box margin-5 ${msgItem && msgItem.feedback === 'bad'?'like-act-bad':''}`} 
                            onClick={() => {
                                chatLikeOrStomp('bad',messageId)
                            }}>
                                <Icon type="thumbs-down" />
                            </div>
                            : null
                        }
                    </div>
                </Flex>
            );    
        default:
            return null;
        }
    }



    
//   return (
//     <Chat
//         messages={messages}
//         renderMessageContent={renderMessageContent}
//         onQuickReplyClick={handleQuickReplyClick}
//         onSend={handleSend}
//     />
//   );

    return <ChatUiToZy />;
}

export default App;
