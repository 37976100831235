import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import rootAvatar from '../assets/rootAvatar.png';
const rootUser = { avatar: rootAvatar};

function getUid () {
    var uid = '';
    for (var i = 0; i < 10; i++) {
        uid += String(Math.floor(Math.random()*10));
    }
    return uid+''+Date.now();
}

const ChatUiToZy = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);
    const [sessionId, setSessionId] = useState(getUid());
    const [recordMsgId, setRecordMsgId] = useState([]);

    const searchData = {
        deviceId: searchParams.get('deviceId'),
        userId: searchParams.get('userId'),
        appVersion: searchParams.get('appVersion'),
        mobileMode: searchParams.get('mobileMode'),
    };
    console.log(searchData);

    const wrapper = useRef();

    // 首字母更换成大写
    function upperCaseBotMsg(msg) {
        let newMsg = msg;
        if (msg instanceof Array) {
          newMsg = msg.map((item) => {
            return upperCaseBotMsg(item);
          });
        } else if (typeof msg == 'object' && msg) {
          newMsg = {};
          for (const key in msg) {
            newMsg[key.substring(0, 1).toUpperCase() + key.substring(1)] = upperCaseBotMsg(msg[key]);
          }
        }
        return newMsg;
    }

    // 点赞 点踩
    const chatLikeOrStomp = (feedback,messageId)=> {
        console.log(recordMsgId);
        const isRecord = recordMsgId.find(item => item === messageId);
        console.log(isRecord,messageId);
        //  如果存在就不在调用
        if (isRecord) {
            return;
        }
        console.log(isRecord,messageId,'22222222');
        axios.post('/sys/zyCloudDeviceProblem/chatLikeOrStomp',{
            messageId: messageId,
            feedback: feedback,
            sessionId: sessionId
        }).then(res => {
            recordMsgId.push(messageId);
            setRecordMsgId(recordMsgId);
            if (res.data.code === 0) {
                var feedMsg = '谢谢您给我的赞，我会继续努力！'
                if (feedback === 'bad') {
                    feedMsg = "抱歉，给您带来不好的体验，我会继续学习，下次为您提供更好的服务！";
                }
                return;
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        const bot = new window.ChatSDK({
            root: wrapper.current,
            config: {
                avatarWhiteList: ['knowledge', 'recommend','text'],
                // navbar: {
                //     title: '智能助理'
                // },
                robot: {
                    avatar: rootAvatar,//'//gw.alicdn.com/tfs/TB1U7FBiAT2gK0jSZPcXXcKkpXa-108-108.jpg'
                },
                messages: [
                    {
                        type: 'text',
                        content: {
                            text: '智能助理为您服务，请问有什么可以帮您？'
                        }
                    }
                ],
                feedback: {
                    // 点赞后出的文本
                    textOfGood: '感谢您的评价，我们会继续努力的哦！',
                    // 点踩后出的文本
                    textOfBad: '抱歉，给您带来不好的体验，我会继续学习，下次为您提供更好的服务！',
                    // 点踩后是否显示反馈表单
                    needFeedback: false,
                    repeatMessage: '你已完成评价，无需重复提交'
                }
            },
            requests: {
                send: function (msg) {
                    console.log(msg);
                    if (msg.type === 'text') {
                        // sendChatMsg(msg.content.text);
                        console.log(msg.content.text);
                        return axios.post('/sys/zyCloudDeviceProblem/createChat',{
                            "deviceId": searchData.deviceId,
                            "userId": searchData.userId,
                            "customerProblem": msg.content.text,
                            "appVersion": searchData.appVersion,
                            "mobileMode": searchData.mobileMode,
                            "sessionId": sessionId,
                        }).then(res=> {
                            if (res.data.code !== 0) {
                                return {};
                            }
                            const callbackRes = upperCaseBotMsg(res.data.data.body);
                            return callbackRes;
                        })
                    }
                },
                evaluate: (data) => {
                    chatLikeOrStomp(data.type, data.msgId);
                }
            },
            handlers: {
                parseResponse: function (res, requestType) {
                    if (requestType === 'send') {
                        if(!res.Messages[0].Knowledge.Content) {
                            res.Messages[0].Knowledge = null;
                        }
                        const parseMsg = window.isvParser.default({data: res}) // 
                        console.log(parseMsg);
                        if (parseMsg[0].meta.answerSource !== 'KnowledgeBase') {
                            parseMsg[0].meta.evaluable = false;
                        }
                        return parseMsg;
                    } 
                    return res;  
                }
            },
            components: {

            }
        });
    
        bot.run();
        window.bot = bot;
    }, []);

    // 注意 wrapper 的高度
    return <div style={{ height: '100%' }} ref={wrapper} />;
}

export default ChatUiToZy;